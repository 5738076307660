/*BODY*/

:root {
  --top-scroll-position: 0;
}

body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.CookieConsent {
  bottom: 32px !important;
}

.CookieConsent div {
  flex: auto !important;
}

/*NAVIGATION*/

.navbar {
  height: 70px;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  -webkit-transition: 2s;
}

.navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-transition: 2s;
}

.nav-items {
  color: #000000;
  cursor: pointer;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-items .nav-item {
  float: right;
  height: 30px;

  position: relative;
  right: 150px;
  text-transform: uppercase;
  font-family: "Roboto";
  font-weight: 900;
  -webkit-transition: 2s;
  height: 22px;
  line-height: 22px;
  padding: 24px 0;
}

.nav-items .page-sliders {
  display: inline;
}

.nav-items .nav-item a {
  color: #ffffff;
  padding: 10px;
}

.nav-items .nav-item a:hover {
  color: #6ca392;
}

.nav-items .nav-item img {
  position: absolute;
  right: -25px;
  top: 20px;
}
.nav-items .nav-link {
  padding-top: 5px;
  color: #ffffff;
  text-decoration: none;
}

.nav-items .nav-link:hover {
  color: #6ca392;
}

.nav-items .home-item {
  float: left;
  padding: 14px;
  position: relative;
  left: 150px;
  -webkit-transition: 2s;
  cursor: pointer;
}

.nav-items .home-item.burgermenu {
  display: none;
}

.nav-items .home-item:hover {
  transform: scale(1.2);
  -webkit-transition: 1s;
}

.nav-items .language-button {
  position: relative;
  font-size: small;
  right: 10px;
  top: -10px;
}
.nav-items .language-button button {
  background-color: transparent;
  color: white;
  border: transparent;
  font-weight: bold;
  cursor: pointer;
}

.nav-items .language-button .languagetext .active {
  opacity: 0.4;
}

.nav-items .language-button button:hover .languagetext .nl.active {
  color: #f59a75;
  opacity: 1;
}

.nav-items .language-button button:hover .languagetext .en.active {
  color: #f59a75;
  opacity: 1;
}

.nav-items .language-button .languagetext p {
  display: inline-block;
}

.dropdown {
  display: inline-block;
  /*position: relative;*/
}

.dropdown-content {
  position: absolute;
  display: none;
  right: -37px;
  min-width: 150px;
  padding: 5px;
  z-index: 1;
  background-color: #000;
  margin: 5px 0 0;
  color: #fff;
  border-radius: 5px;
  list-style: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropdown-content li a {
  color: #fff;
  text-decoration: none;
  text-align: right;
}

.dropdown:hover .dropdown-content li a:hover {
  color: #6ca392;
}

.loading {
  display: block !important;
}

/*BANNER*/

.header {
  min-height: 80vh;
  background-color: #6ca392;
  overflow: hidden;
  opacity: 0.7;
  animation: headerfade 3s forwards;
}

@keyframes headerfade {
  100% {
    box-shadow: 0px -30px 80px 10px black;
    opacity: 1;
  }
}

.left-header {
  float: left;
  width: 33%;
  min-height: 80vh;
  background-color: #f59a75;
}

.right-header {
  float: right;
  width: 33%;
  min-height: 80vh;
  background-color: #fddb82;
}
.t-text {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text !important;
  /*background: url(http://localhost:3000/Tea2.jpg) 14% 26%;*/
  background: url(https://thumbs.gfycat.com/BarrenCreamyGiantschnauzer-size_restricted.gif)
    20% 91%;
  background-size: 184%;
  text-shadow: 3px 3px rgba(255, 255, 255, 0.25) !important;
}
.header-text {
  float: left;
  float: left;
  font-size: 112px;
  text-transform: uppercase;
  font-family: "Roboto";
  font-weight: 900;
  color: #fff;
  text-shadow: 4px 4px rgba(245, 154, 117, 0.65);
}
.t-banner {
  margin: 40vh auto 0;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  width: fit-content;
  position: absolute;
  display: flex;
}

/*CONTENT*/
.nav-content {
  height: 70px;
  white-space: nowrap;
}

.sectiondiv {
  /* min-height: 100px; */
  overflow: hidden;
  /* min-height: 800px; */
  padding: 10px;
  max-width: 1100px;
  /* display: contents; */
  margin: 0 auto;
  white-space: nowrap;
}

.sectiondiv#Ourteas {
  min-height: 700px;
}

.content {
  overflow: visible;
}

.content-margin {
  margin: 70px 0;
}

.content-title {
  height: 122px;
}

.content-title h1 {
  position: relative;
  top: 35px;
  top: 35px;
}

.column {
  float: left;
  width: 25%;
  text-align: center;
}

.column a,
.column label,
.column p {
  padding: 0 25px;
  white-space: normal;
}

.row {
  min-height: 350px;
  margin: 96px 0;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.diagonal-box {
  width: 100%;
  height: 4vw;
  top: -1px;
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 0 100%, 100% 0, 100% 0);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
  background-color: #fff;
}

.flip {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.align-right {
  text-align: right;
}

.diagonalbox-content {
  max-width: 50em;
  margin: 0 auto;
}

/*STEPS PAGE*/

.steps-holder {
  height: 200px;
  display: flex;
  gap: 30px;
  justify-content: center;
}

.steps-numbers {
  font-size: 48px;
  text-align: center;
  float: left;
  padding: 0 15px;
  align-content: center;
}

.steps-text {
  display: inline-block;
  position: relative;
  top: 25%;
  white-space: initial;
}

.steps-flexbox {
  width: 25%;
  justify-content: center;
}

.steps-alignright {
  text-align: right;
}

/* SUBSCIRPTION*/

.sub-holder {
  display: flex;
  gap: 30px;
  clear: both;
  white-space: normal;
}

.sub-column {
  width: 20%;
  text-align: center;
  background-color: #ffe8ad;
  pointer-events: none;
  position: relative;
}

.sub-text {
  padding: 20px;
}
.sub-icon {
  height: 100px;
}

.pricetag {
  border-radius: 25px;
  padding: 5px 10px;
  background-color: #000;
  font-weight: 600;
  color: #fff;
  position: absolute;
  right: 10px;
  bottom: -10px;
  font-size: 12px;
}

.sub-order {
  padding: 10px 20px;
  margin: 8px 0 48px;
  background-color: #000;
  color: #fff;
  float: right;
  border: 0px;
  font-weight: 600;
  font-size: 16px;
}

.sub-order:hover {
  background-color: #6ca392;
  color: #fff;
  cursor: pointer;
}

/*TITLE UNDERLINE*/

.Underline-box {
  height: 5px;
  width: 38%;
  background-color: #fddb82;
  position: absolute;
  left: 0px;
  overflow: hidden;
}

.Underline-box {
  left: 0px;
}

.Underline-box2 {
  height: 5px;
  background-color: #f59a75;
  width: 200px;
  float: left;
}

.Underline-box3 {
  height: 5px;
  background-color: #6ca392;
  width: 100px;
  float: right;
}

.right {
  right: 0px;
  left: auto;
}

/*FOOTER*/

.footer-div {
  position: relative;
  color: #ffffff;
  padding: 10px 0;
  color: #fff;
  min-height: 230px;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
}

.footerbg .row {
  margin: 0px;
  display: flex;
  justify-content: flex-end;
}

.footerbg .row .column {
  min-height: 200px;
  color: #fff;
  text-align: end;
  width: 18%;
}

.footerbg .row h2 {
  font-weight: 300;
  margin: 5px 22px;
  line-height: 48px;
}

.footerbg .row label,
.footerbg .row p,
.footerbg .row a {
  margin: 3px 0;
  color: #000;
  opacity: 0.7;
  font-size: 13px;
  cursor: pointer;
  display: block;
}

.footerbg .row p:hover,
.footerbg .row label:hover {
  text-decoration: underline;
}

.footer-text {
  color: #000;
  opacity: 0.7;
  font-size: 13px;
  width: calc(100% - 40px);
  line-height: 40px;
  margin: 0 20px;
  display: inline-block;
  border-top: 2px solid rgba(255, 255, 255, 0.7);
}

.footer-icon-bg {
  background-color: #ffffff;
  width: 44px;
  border-radius: 50%;
}

.footer-icons {
  margin: 0 auto;
  display: inline-flex;
  width: 40px;
  padding-right: 45px;
  filter: invert(100%) sepia(0%) saturate(7485%) hue-rotate(15deg)
    brightness(103%) contrast(104%);
}

.footer-icons:hover img {
  animation: hover-icons 2s forwards;
  cursor: pointer;
}

@keyframes hover-icons {
  15% {
    width: 37px;
  }

  50% {
    width: 43px;
  }

  100% {
    width: 40px;
  }
}

.greenbg {
  background-color: #6ca392;
}

.yellowbg {
  background-color: #fddb82;
}

.redbg {
  background-color: #f59a75;
}

.footerbg {
  background-color: #9b9b9b;
  box-shadow: 0px 40px 80px 10px black;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footerbg .row {
  min-height: auto;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 28px;
}

.form {
  max-width: 500px;
  margin: 0 auto;
}

.form > div {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.form label {
  margin: 10px 0;
  display: block;
}

.form input {
  padding: 10px;
  display: block;
  min-width: 250px;
  width: calc(100% - 24px);
}

.form .link {
  cursor: pointer;
  text-decoration: underline;
}

.form .link:hover {
  color: #9b9b9b;
}

.notification.warning {
  background-color: rgba(255, 0, 0, 0.2);
  padding: 10px;
  border-radius: 4px;
  display: flex;
  line-height: 24px;
}

.notification.warning img {
  margin-right: 8px;
  position: relative;
  top: -1px;
}

.button-holder {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-end;
  gap: 16px;
  margin: 8px 0;
}

button.primary,
button.continue {
  height: 48px;
  width: 100%;
  padding: 10px;
  background-color: #f59a75;
  color: #fff;
  border: 0px;
  font-weight: 800;
  cursor: pointer;
  display: block;
  margin: 20px 0;
  box-shadow: 0 4px 0-2px rgba(3, 3, 26, 0.25);
}

button.secondary {
  background-color: #fff;
  width: 80%;
  color: #f59a75;
  border: 3px solid transparent;
  height: 48px;
  font-weight: 800;
  cursor: pointer;
  display: block;
  margin: 20px 0;
}

button.secondary:hover {
  box-shadow: 0 4px 0-2px rgba(3, 3, 26, 0.25);
  border: 3px solid #f59a75;
  opacity: 0.8;
  -webkit-transition: border-color, 1s;
}

button.warning,
button.save,
button.edit {
  width: fit-content;
  height: 48px;
  padding: 10px;
  background-color: #f59a75;
  color: #fff;
  border: 0px;
  font-weight: 800;
  cursor: pointer;
  box-shadow: 0 4px 0-2px rgba(3, 3, 26, 0.25);
}

button.save {
  background-color: #3cb371;
}

button.save.disabled {
  background-color: #5e716a;
  opacity: 0.4;
  pointer-events: none;
}

button.warning {
  background-color: #fff;
  color: #d9534f;
  box-shadow: 0 4px 0-2px rgba(3, 3, 26, 0);
  border: 3px solid transparent;
}

button.warning:hover {
  box-shadow: 0 4px 0-2px rgba(3, 3, 26, 0.25);
  border: 3px solid #d9534f;
  opacity: 0.8;
  -webkit-transition: border-color, 1s;
}
button:hover {
  opacity: 0.8;
  -webkit-transition: 1s;
}

.download {
  cursor: pointer;
}

/*CAROUSEL OUR TEAS*/
.carousel-teas {
  margin-top: 100px;
}

.carousel-teas .jMNKZk {
  width: 140% !important;
}

.carousel-teas .jRFJkP {
  background-color: transparent !important;
  background-image: url("http://localhost:3000/img/icons/navigation/arrow-left.svg");
  background-repeat: no-repeat;
  background-size: 50px;
  opacity: 1;
  box-shadow: none !important;
  color: transparent !important;
}

/*.carousel-teas .jRFJkP.rec-arrow-left {*/
/*  -moz-transform: scale(-1, 1);*/
/*  -webkit-transform: scale(-1, 1);*/
/*  -o-transform: scale(-1, 1);*/
/*  -ms-transform: scale(-1, 1);*/
/*  transform: scale(-1, 1);*/
/*}*/

.carousel-teas .rec-arrow-right,
.carousel-teas .rec-arrow-left {
  background-color: rgba(108, 163, 146, 0.2);
}

.carousel-teas .rec-arrow-left:hover:enabled,
.carousel-teas .rec-arrow-left:active,
.carousel-teas .rec-arrow-left:focus:enabled,
.carousel-teas .rec-arrow-right:hover:enabled,
.carousel-teas .rec-arrow-right:active,
.carousel-teas .rec-arrow-right:focus:enabled {
  background-color: rgba(108, 163, 146, 1);
  box-shadow: none;
}

.carousel-teas button.rec-dot {
  background-color: rgba(108, 163, 146, 0.5);
  box-shadow: none;
}

.carousel-teas button.rec-dot_active {
  background-color: rgba(108, 163, 146, 0.5);
  box-shadow: 0 0 1px 3px rgba(108, 163, 146, 1);
}

.carousel-teas button.rec-dot:hover,
.carousel-teas button.rec-dot:active,
.carousel-teas button.rec-dot:focus {
  background-color: rgba(108, 163, 146, 1);
  box-shadow: 0 0 1px 3px rgba(158, 163, 146, 1);
}

/*.jRFJkP:hover:enabled, .jRFJkP:focus:enabled {*/
/*  background-color: #6ca392 !important;*/
/*  filter: invert(99%) sepia(1%) saturate(0%) hue-rotate(167deg) brightness(116%) contrast(100%);*/
/*  opacity: 1;*/
/*}*/

.gZZBOx,
.rec.rec-arrow:disabled {
  visibility: hidden;
  color: transparent !important;
}

.rec.rec-arrow {
  border-radius: 50%;
  box-shadow: none;
  opacity: 1;
  background-color: rgba(108, 163, 146, 0.4);
}

.jRFJkP:hover:enabled,
.jRFJkP:focus:enabled {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.4) !important;
  background-color: #6ca392 !important;
}

.carousel {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  clear: both;
  white-space: nowrap;
}

#Ourteas.sectiondiv .productBanner {
  height: 160px;
  -webkit-transition: 1s;
  /*pointer-events: none;*/
}

#Ourteas.sectiondiv .productBanner:hover {
  margin-top: 0px;
  height: 260px;
  -webkit-transition: 0.5s;
  background-color: transparent;
  box-shadow: none;
}

#Ourteas.sectiondiv .productBanner:hover .productDescription p {
  margin-top: 0px;
  -webkit-transition: 0.5s;
}

.sectiondiv .subscriptions-teas {
  margin-top: 48px;
  min-height: 350px;
}

.productBanner {
  width: 200px;
  margin: 10px;
  padding: 10px;
  border-radius: 25px;
  float: left;
  position: relative;
  display: inline-block;
  margin-top: 100px;
  pointer-events: none;
  -webkit-transition: 0.5s;
  white-space: normal;
}

.productDescription {
  margin-top: 100px;
  text-align: center;
}

.productDescription h2 {
  font-size: 18px;
  height: 44px;
}

.productFooter {
  background-color: aqua;
  position: absolute;
  bottom: 0px;
  width: 220px;
  left: 0px;
  height: 25px;
  overflow: hidden;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
.productFoooterText {
  color: #fff;
  font-weight: 900;
  margin: 0;
  font-size: 14px;
  background-color: #648923;
  border-radius: 25px;
  padding: 5px 10px;
  clear: both;
  display: inline;
  position: relative;
  top: -66px;
  /* font-size: 14px; */
  right: -5px;
  white-space: pre;
  z-index: 1;
}

.productImage {
  width: 190px;
  height: 190px;
  top: -75px;
  right: 15px;
  border-radius: 50%;
  position: absolute;
}

.dot {
  height: 200px;
  width: 200px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -80px;
  right: 10px;
  opacity: 0.6;
}

/*MY AOCOUNT */
.myaccount-content {
  max-width: 1100px;
  min-width: 90vw;
  margin: 70px auto;
}
.account-menu {
  width: 250px;
  float: left;
  /*border-right: 1px solid #6ca392;*/
  margin-right: 25px;
}

.account-menu .menu-icon {
  position: relative;
  padding: 0 2px;
  top: 6px;
  left: -4px;
}

.account-menu ul li {
  list-style: none;
  padding: 0.75em;
  cursor: pointer;
}

.account-menu ul li:hover {
  color: #f59a75;
  border-left: 5px solid #f59a75;
}

.account-menu ul li.active {
  border-left: 5px solid #f59a75;
}

.account-form-fieldset {
  border-width: 0px;
}

.account-overview {
  display: inline-block;
}

/* Checkout Steps */
.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1;
  padding: 1rem;
  font-weight: bold;
}
.checkout-steps > div.active {
  border-top-color: #f08000;
  color: #f08000;
}

.myAccountBody {
  width: calc(100% - 300px);
  display: flex;
  flex-direction: column;
}

.MySubscription {
  display: inline-block;
  width: 100%;
}

.MySubscription label {
  line-height: 32px;
}

.MySubscription .mysub-Holder {
  margin: 12px 0;
  padding: 12px 28px 12px 0px;
}

.MySubscription .confirmCancel {
  display: flex;
  justify-content: end;
  margin: 24px 0px;
  background-color: rgba(0, 0, 0, 0.1);
  flex-direction: column;
  padding: 12px;
  max-width: 312px;
  border-radius: 8px;
  box-shadow: 0 4px 0-2px rgba(3, 3, 26, 0.25);
  float: right;
}

.mySub-Section {
  border: 1px solid rgba(224, 224, 224, 1);
}

.mySub-Section .mySub-wrap {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.mySub-Section .label {
  font-weight: 600;
}

.mySub-Section .info {
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
}

.mySub-Section .info label {
  line-height: 24px;
}

.mySub-Section img {
  position: relative;
  top: 8px;
  padding-right: 14px;
}

.MySubscription .mySub-column {
  width: 50%;
  display: inline-block;
}

.table-label {
  display: flex;
  text-transform: capitalize;
}

/* Choose sub  */

.sectionsub {
  min-height: 60vh;
  min-width: 50vw;
  max-width: 1100px;
  margin: 0 auto;
  padding: 70px 0;
}

.sectionsub .content {
  padding: 24px 0;
}

.sectionsub .sub-column {
  pointer-events: all;
  opacity: 0.75;
}

.sectionsub .sub-column:hover {
  background-color: #fddb82;
  box-shadow: 0 4px 0-2px rgba(3, 3, 26, 0.25);
  opacity: 1;
}
.sectionsub .sub-column.active {
  background-color: #fddb82;
  opacity: 1;
}

.sectionsub .rec-carousel-wrapper .jMNKZk {
  max-width: 100% !important;
}

.productBanner {
  background-color: #f59a75;
  pointer-events: all;
}

.myAccountBody .flavors,
.sectionsub .flavors {
  display: flex;
  gap: 30px;
  clear: both;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.sectionsub .bagged {
  background-color: #91baad;
  margin-right: 30px;
  display: inline-block;
  color: white;
  padding: 25px;
  cursor: pointer;
  box-shadow: 0 4px 0-2px rgba(3, 3, 26, 0.25);
  opacity: 0.7;
}

.sectionsub .bagged:hover,
.sectionsub .bagged.active {
  background-color: #6ca392;
  opacity: 1;
}

.myAccountBody .productImage,
.sectionsub .productImage {
  right: 5px;
}

.myAccountBody .productBanner .dot,
.sectionsub .productBanner .dot {
  background-color: #f59a75;
  right: 0px;
}
.myAccountBody .productBanner,
.sectionsub .productBanner {
  height: 170px;
  width: 180px;
  background-color: transparent;
}

.myAccountBody .productBanner.active,
.sectionsub .productBanner.active {
  background-color: #f59a75;
  box-shadow: 0 4px 0-2px rgba(3, 3, 26, 0.25);
}

.productBanner:hover {
  background-color: #f59a75;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
}
.myAccountBody .productBanner:hover .dot,
.sectionsub .productBanner:hover .dot {
  background-color: #f59a75;
  /*box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);*/
}

.sectionsub .productBanner h2 {
  text-align: center;
  font-size: 20px;
}

.myAccountBody .productBanner .productText,
.myAccountBody .productBanner .productFooter,
.sectionsub .productBanner .productText,
.sectionsub .productBanner .productFooter {
  display: none;
}

.sectionsub .continue {
  width: fit-content;
  padding: 0 24px;
}

.sectionsub .password-eye {
  position: absolute;
  top: 19%;
  right: 2%;
  cursor: pointer;
}
.sectionsub .password-container {
  position: relative;
}

.section-paragraph {
  margin-bottom: 80px;
  text-align: justify;
  padding: 0 15px;
}

.section-paragraph h2 {
  color: #f59a75;
  font-size: 18px;
}
.section-paragraph label {
  font-weight: 600;
}
.section-paragraph p {
}
