@media (max-width: 992px) {
  .CookieConsent {
    left: 32px !important;
    width: calc(100% - 64px) !important;
  }
  .header-text {
    font-size: 80px;
  }

  .MySubscription .mySub-column {
    width: 100%;
  }
  .nav-items .home-item {
    left: 0px;
  }
  .nav-items .nav-item {
    right: 75px;
  }
  .nav-item.language-button {
    right: 0px !important;
  }
  .sectiondiv {
    /*max-width: 700px;*/
    padding: 25px;
  }

  .row {
    min-height: 500px;
    margin: 96px 0;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
  }

  .footerbg .row {
    margin: 0;
  }

  .column {
    width: 50%;
  }

  .sub-holder {
    display: flex;
    gap: 30px;
    clear: both;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .sub-column {
    width: 28%;
  }

  .footer-column {
    width: 160px;
  }

  .sub-order {
    margin: 48px 0;
  }
}

@media (max-width: 768px) {
  .footer-column {
    width: 20%;
  }
  .myAccountBody {
    width: calc(100% - 32px);
    margin: 16px;
  }
  .account-menu {
    width: 100%;
  }

  .footerbg .row {
    display: flex;
    justify-content: center;
  }

  .footerbg .row .column {
    width: 50%;
  }

  .nav-items .page-sliders {
    display: none;
    width: 100%;
    margin-top: 0px;
    list-style: none;
    margin-left: 45px;
    position: relative;
    top: -4px;
  }

  .nav-items .home-item.burgermenu {
    display: grid;
  }

  .nav-items .page-sliders.active {
    display: grid;
  }

  .page-sliders .nav-item {
    float: initial;
    margin-top: 0px;
    background-color: #000000;
    list-style: none;
    height: 27px;
    line-height: 28px;
  }
  .nav-items .home-item {
  }
  .nav-items .home-item:hover {
    transform: none;
    -webkit-transition: 1s;
  }
  .nav-items .nav-item {
    right: 45px;
  }
  .sub-column h2 {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .right-header,
  .left-header,
  .header {
    min-height: 40vh;
  }

  .t-banner {
    margin: 20vh auto 0;
  }

  .header-text {
    font-size: 60px;
  }
}

@media (max-width: 426px) {
  .sub-column {
    width: 100%;
    margin: 0 12px;
  }
}


@media (max-width: 352px) {
  .header-text {
    font-size: 40px;
  }
}
